import {DialogAction, DialogActionRole, DialogComponentProps, DialogType} from '@core/types';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import React, {ReactElement} from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

export const DialogComponent = (props: DialogComponentProps): ReactElement<DialogComponentProps> => {
  const {title, message, type, actions, onClose} = props;
  const styles = {
    [DialogType.success]: {
      text: 'text-white',
      color: 'green',
      icon: <DoneIcon className="h-6 w-6 text-green-600"/>,
    },
    [DialogType.error]: {
      text: 'text-white',
      color: 'red',
      icon: <ErrorIcon className="h-6 w-6 text-red-600"/>,
    },
    [DialogType.warning]: {
      text: 'text-white',
      color: 'orange',
      icon: <WarningIcon className="h-6 w-6 text-orange-600"/>,
    },
    [DialogType.info]: {
      text: 'text-white',
      color: 'blue',
      icon: <InfoIcon className="h-6 w-6 text-blue-600"/>,
    },
  };

  if (!styles[type]) {
    throw Error(`Unknown dialog type "${type}"`);
  }

  const roles = {
    [DialogActionRole.yes]: {
      className: `${styles[type].text} bg-${styles[type].color}-600 hover:bg-${styles[type].color}-700 focus:ring-${styles[type].color}-500 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`,
    },
    [DialogActionRole.cancel]: {
      className: `focus:ring-${styles[type].color}-500 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`,
    },
  };

  const button = (action: DialogAction): ReactElement => {
    const role = roles[action.role || DialogActionRole.cancel] ?? roles[DialogActionRole.cancel];

    return (
      <Button
        key={action.text}
        type="button"
        className={role.className}
        onClick={(): void => {
          action.handler && action.handler(action.data ?? {});
          onClose && onClose();
        }}
      >
        {action.text}
      </Button>
    );
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={(): void => onClose && onClose()}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div
              className={`bg-${styles[type].color}-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10`}>
              {styles[type].icon}
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle className="text-lg leading-6 font-medium text-gray-900 pt-0">
                {title}
              </DialogTitle>
              <DialogContent className="mt-2">
                <p className="text-sm text-gray-500">
                  {message}
                </p>
              </DialogContent>
            </div>
          </div>
        </div>
        <DialogActions className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-start">
          {actions ? actions.map((action) => button(action)) : button({text: 'OK'})}
        </DialogActions>
      </Dialog>
    </>
  );
};
