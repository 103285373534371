import React, {ChangeEvent, memo, ReactElement, useEffect, useState} from 'react';
import {DialogActionRole, DialogType} from '@core/types';
import {descendingComparator, emptyComparator, getMarkdownText, truncate} from '@core/utils';
import {createSearchParams, Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import {Close, PostAdd, Search} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useEntitiesList, useEntitiesRemove} from '@modules/entities/hooks';
import {EntitiesPageListColumn, Entity, EntityStatuses} from '@modules/entities/types';
import ArticleIcon from '@mui/icons-material/Article';
import {get} from 'lodash';
import {useSnackbar} from 'notistack';
import {useDialogs} from '@core/hooks';

export const EntitiesListPage = memo(() => {
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const columns: EntitiesPageListColumn[] = [
    {
      name: 'ID/Пользователь',
      value: ['id', 'data.owner'],
      order: ['id', 'data.owner'],
    },
    {
      name: 'CPU/MB',
      value: ['refs.cpu.data.name', 'refs.mb.data.name'],
      color: ['refs.cpu.data.color', 'refs.mb.data.color'],
      order: ['refs.cpu.data.name', 'refs.mb.data.name'],
    },
    {
      name: 'SSD/HDD',
      value: ['refs.ssd.data.name', 'refs.hdd.data.name'],
      color: ['refs.ssd.data.color', 'refs.hdd.data.color'],
      order: ['refs.ssd.data.name', 'refs.hdd.data.name'],
    },
    {
      name: 'RAM',
      value: [(row): string => row.data.ram?.join('/') ?? ''],
      order: ['data.ram'],
    },
    {
      name: 'GPU/PSU',
      value: ['refs.gpu.data.name', 'refs.psu.data.name'],
      color: ['refs.gpu.data.color', 'refs.psu.data.color'],
      order: ['refs.gpu.data.name', 'refs.psu.data.name'],
    },
    {
      name: 'Комментарий/Тип',
      value: [(row): ReactElement => {
        const html = getMarkdownText(row.data.comment ?? '');
        const short = getMarkdownText(truncate(row.data.comment ?? '', 30).split('\n')[0]);
        // if (row.data.comment && row.data.comment.length > 30) {
        return (
          <Tooltip title={<div dangerouslySetInnerHTML={html}/>}>
            <div dangerouslySetInnerHTML={short}/>
          </Tooltip>
        );
        // }
        // return <div dangerouslySetInnerHTML={html}/>;
      }, 'refs.device.data.name'],
      color: ['', 'refs.device.data.color'],
      order: ['data.comment', 'refs.device.data.name'],
    },
  ];
  const [entities, loading] = useEntitiesList();
  const [filtered, setFiltered] = useState<Entity[]>([]);
  const [search, setSearch] = useState(query.get('search') ?? '');
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [order, setOrder] = useState<string>('createAt');
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('desc');
  const [remove] = useEntitiesRemove();
  const {id} = useParams<{ id: string }>();
  const {enqueueSnackbar} = useSnackbar();
  const statusColors = {
    [EntityStatuses.success]: '',
    [EntityStatuses.warning]: 'bg-amber-50',
    [EntityStatuses.danger]: 'bg-red-50',
  };

  // edit panel state
  useEffect(() => {
    const state = !!id || location.pathname.includes('create');

    setOpenEditPanel(state);
  }, [id, location]);

  useEffect(() => {
    if (id || location.pathname.includes('create')) {
      return;
    }

    navigate({
      pathname: '/entities',
      search: search ? `?${createSearchParams({search: search})}` : '',
    });
  }, [openEditPanel, search, id, navigate, location.pathname]);

  // entities list and search text
  useEffect(() => {
    if (!entities?.length) {
      return;
    }

    setFiltered(
      entities
        .filter((row: Entity) => row.search(search))
        .sort((a: Entity, b: Entity) => {
          const aValue = get(a, order);
          const bValue = get(b, order);

          return emptyComparator(aValue, bValue) ?? (descendingComparator(aValue, bValue) * (orderDir === 'desc' ? 1 : -1));
        }),
    );
  }, [entities, search, order, orderDir]);

  // action "delete" for row in table
  const handleDeleteItem = (item: Entity): void => {
    if (!item) {
      return;
    }

    dialogs.show({
      title: 'Удалить запись',
      message: `Вы действительно хотите удалить запись "${item.id}"?`,
      type: DialogType.warning,
      actions: [
        {
          text: 'Удалить',
          role: DialogActionRole.yes,
          handler: (): void => {
            if (!item.id) {
              return;
            }

            remove(item.id).then(() => enqueueSnackbar(
              `Запись "${item.id}" успешно удалена`,
              {variant: 'success'},
            ));
          },
        },
        {text: 'Отмена'},
      ],
    });
  };

  const handleOrder = (column: EntitiesPageListColumn): void => {
    if (!column.order) {
      return;
    }

    const index = column.order.indexOf(order);

    if (index !== -1) {
      if (orderDir === 'asc') {
        return setOrderDir('desc');
      }

      if ((column.order.length - 1) > index) {
        setOrder(column.order[index + 1]);
        setOrderDir('asc');
        return;
      }
    }

    setOrder(column.order[0]);
    setOrderDir('asc');
  };

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto">
        <header className="bg-white border-b">
          <div className="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
            {/* <h1 className="text-3xl font-bold text-gray-900">Техника</h1>*/}
            <div className="flex items-center justify-between flex-col">
              <div className="flex-1 w-full mb-4">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  Реестр
                </h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="flex items-center text-gray-500">
                    Записей: {filtered?.length ?? 0}
                  </div>
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                </div>
              </div>

              <div className="w-full flex">
                <OutlinedInput
                  fullWidth
                  placeholder="Поиск..."
                  value={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value)}
                  className="flex-1 mr-4"
                  inputProps={{
                    className: 'py-2 leading-7 h-7',
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search/>
                    </InputAdornment>
                  }
                  endAdornment={
                    search &&
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={(): void => setSearch('')}>
                        <Close fontSize="small"/>
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* </div> */}

                {/* <div className="mt-5 flex lg:mt-0 lg:ml-4 justify-end"> */}
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={(): void => navigate('/entities/create')}
                  startIcon={<PostAdd/>}
                  className="py-2 leading-7"
                >
                  Создать
                </Button>
              </div>
            </div>
          </div>
        </header>

        <main className="sm:overflow-auto flex-1">
          <div className="max-w-full mx-auto sm:py-6 sm:px-6 lg:px-8">

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) =>
                      <TableCell
                        className="w-1/7"
                        // className="font-bold text-gray-500 uppercase tracking-wider"
                        // size="small"
                        key={column.name}
                      >
                        <TableSortLabel
                          // hideSortIcon={!column.order?.length}
                          active={(column.order ?? []).includes(order)}
                          direction={orderDir}
                          onClick={(): void => handleOrder(column)}
                          classes={{active: 'text-gray-500'}}
                        >
                          {column.name.split('/').map((item, index) => {
                            const className = (column.order ?? []).indexOf(order) === index ? 'text-orange-500' : '';
                            const prefix = index !== 0 ? '/' : '';

                            return (
                              <span key={index}>
                                <span>{prefix}</span>
                                <span className={className}>{item}</span>
                              </span>
                            );
                          })}
                          {/* {column.name} */}
                        </TableSortLabel>
                      </TableCell>,
                    )}
                    <TableCell className="w-1/7"/>
                  </TableRow>
                </TableHead>
                <TableBody className="divide-y divide-gray-50">
                  {filtered.length ? filtered.map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        // className="hover:bg-gray-50"
                        className={statusColors[row.data.status as EntityStatuses]}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {columns.map(({name, value, color = []}) =>
                          <TableCell className="border-0" key={`${row.id}-${name}`}>
                            {/* {row[column.value]}*/}
                            {value[0] &&
                              <div className={`font-medium text-${get(row, color[0]) || 'gray'}-900 truncate`}>
                                {typeof value[0] === 'function' ? value[0](row) : get(row, value[0])}
                              </div>
                            }
                            {value[1] &&
                              <div className={`text-${get(row, color[1]) ?? 'gray'}-500 truncate`}>
                                {typeof value[1] === 'function' ? value[1](row) : get(row, value[1])}
                              </div>
                            }
                          </TableCell>,
                        )}

                        <TableCell align="right" className="whitespace-nowrap border-0">
                          <Tooltip title="Просмотр">
                            <Link
                              className="cursor-pointer w-10 h-10 p-2 flex-0 inline-flex text-gray-500 hover:text-gray-900"
                              to={`/entities/${row.id}`}
                            >
                              <ArticleIcon/>
                            </Link>
                          </Tooltip>

                          <Tooltip title="Редактировать">
                            <Link
                              className="cursor-pointer w-10 h-10 p-2 flex-0 inline-flex text-gray-500 hover:text-gray-900"
                              to={`/entities/${row.id}/edit`}
                            >
                              <EditIcon/>
                            </Link>
                          </Tooltip>

                          <Tooltip title="Удалить">
                            <div
                              className="cursor-pointer w-10 h-10 p-2 flex-0 inline-flex text-gray-500 hover:text-gray-900"
                              onClick={(): void => handleDeleteItem(row)}
                            >
                              <DeleteIcon/>
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )) :
                    <TableRow>
                      <TableCell align="center" colSpan={columns.length + 1}>
                        {loading ? 'Загрузка' : 'Нет данных'}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Drawer
            anchor="right"
            open={openEditPanel}
            onClose={(): void => navigate('/entities')}
            PaperProps={{className: 'w-full md:w-160 h-full'}}
          >
            <div
              className="h-full flex flex-col"
              role="presentation"
            >
              <Outlet/>
            </div>
          </Drawer>
        </main>
      </div>
    </>
  );
});
