import {Button, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {AuthForm} from '@modules/auth/types';
import {useAuth} from '@core/hooks';
import React, {memo, ReactElement} from 'react';
import {useSnackbar} from 'notistack';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';
import {UserCredential} from '@firebase/auth';

export const AuthLoginPage = memo(() => {
  const {control, handleSubmit} = useForm<AuthForm>({
    defaultValues: {
      email: process.env.REACT_APP_AUTH_EMAIL,
      password: process.env.REACT_APP_AUTH_PASSWORD,
    },
    mode: 'all',
  });
  const auth = useAuth();
  const {enqueueSnackbar} = useSnackbar();

  const submit = (data: UnpackNestedValue<AuthForm>): Promise<UserCredential | string | number> =>
    auth.signin(data.email, data.password)
      .then(() => enqueueSnackbar(
        'Успешный вход в систему',
        {variant: 'success'},
      ))
      .catch(() =>
        enqueueSnackbar(
          'Не правильный логин или пароль',
          {variant: 'error'},
        ),
      );

  return (
    <>
      <div className="min-h-full flex flex-row items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 self-start bg-white p-10 border rounded">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Equipment
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={handleSubmit(submit)}
          >
            {/* <input type="hidden" name="remember" defaultValue="true"/>*/}
            <div className="rounded-md space-y-3">
              <div>
                <Controller
                  name="email"
                  control={control}
                  rules={{required: 'Поле обязательно для заполнения'}}
                  defaultValue={''}
                  render={({field, fieldState}): ReactElement =>
                    <TextField
                      {...field}
                      fullWidth
                      error={fieldState.invalid}
                      label="Email"
                      variant="outlined"
                      helperText={fieldState.error?.message}
                    />
                  }
                />
              </div>

              <div>
                <Controller
                  name="password"
                  control={control}
                  rules={{required: 'Поле обязательно для заполнения'}}
                  defaultValue={''}
                  render={({field, fieldState}): ReactElement =>
                    <TextField
                      {...field}
                      type="password"
                      fullWidth
                      error={fieldState.invalid}
                      label="Пароль"
                      variant="outlined"
                      helperText={fieldState.error?.message}
                    />
                  }
                />
              </div>

            </div>

            <div>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disableElevation
                className=""
                size="large"
              >
                Вход
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
});
