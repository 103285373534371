/* eslint-disable no-unused-vars */

import {QueryDocumentSnapshot} from 'firebase/firestore';

export enum EquipmentDocumentFields {
  name = 'name',
  type = 'type',
  color = 'color',
  updateAt = 'updateAt',
  createAt = 'createAt',
}

export enum EquipmentTypes {
  ssd = 'ssd',
  hdd = 'hdd',
  monitor = 'monitor',
  gpu = 'gpu',
  cpu = 'cpu',
  mb = 'mb',
  psu = 'psu',
  device = 'device',
}

export enum EquipmentColors {
  slate = 'slate',
  gray = 'gray',
  zinc = 'zinc',
  neutral = 'neutral',
  stone = 'stone',
  red = 'red',
  orange = 'orange',
  amber = 'amber',
  yellow = 'yellow',
  lime = 'lime',
  green = 'green',
  emerald = 'emerald',
  teal = 'teal',
  cyan = 'cyan',
  sky = 'sky',
  blue = 'blue',
  indigo = 'indigo',
  violet = 'violet',
  purple = 'purple',
  fuchsia = 'fuchsia',
  pink = 'pink',
  rose = 'rose',
  black = 'black',
  white = 'white',
}

export interface Equipment {
  id: string;
  data: EquipmentDocument;
  snapshot: QueryDocumentSnapshot;
  // refs?: EntitiesRef;
  // updateRefs: (list: Record<string, unknown>, fields: string[]) => void;
  search: (search: string) => boolean;
}

export interface EquipmentDocument
  extends Omit<Partial<Record<keyof typeof EquipmentDocumentFields, string>>, 'color' | 'type' | 'updateAt' | 'createAt'> {
  type?: EquipmentTypes;
  color?: EquipmentColors | '';
}

export interface EquipmentForm extends EquipmentDocument {

}
