/* eslint-disable no-unused-vars */

import {Equipment} from '@modules/equipments/types';
import {QueryDocumentSnapshot} from 'firebase/firestore';

export enum EntityDocumentFields {
  owner = 'owner',
  ram = 'ram',
  status = 'status',
  comment = 'comment',
  host = 'host',
  updateAt = 'updateAt',
  createAt = 'createAt',
}

export enum EntityDocumentRelationsFields {
  device = 'device',
  cpu = 'cpu',
  mb = 'mb',
  ssd = 'ssd',
  hdd = 'hdd',
  gpu = 'gpu',
  psu = 'psu',
  monitor1 = 'monitor1',
  monitor2 = 'monitor2',
}

export enum EntityStatuses {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
}

export interface EntitiesRef {
  device?: Equipment;
  cpu?: Equipment;
  mb?: Equipment;
  ssd?: Equipment;
  hdd?: Equipment;
  gpu?: Equipment;
  psu?: Equipment;
  monitor1?: Equipment;
  monitor2?: Equipment;
}

export interface Entity {
  id: string;
  data: EntityDocument;
  snapshot: QueryDocumentSnapshot;
  refs?: EntitiesRef;
  withRefs: () => Promise<Entity>;
  search: (search: string) => boolean;
}

export interface EntityDocument extends Omit<Partial<Record<keyof typeof EntityDocumentFields, string>>, 'status' | 'ram' | 'updateAt' | 'createAt'> {
  ram?: number[];
  status?: EntityStatuses;
}

export interface EntityForm extends EntityDocument {
  device?: string;
  cpu?: string;
  mb?: string;
  ssd?: string;
  hdd?: string;
  gpu?: string;
  psu?: string;
  monitor1?: string;
  monitor2?: string;
}
