import React, {memo, useEffect} from 'react';
import {EquipmentColors, EquipmentForm, EquipmentsFormComponentProps, EquipmentTypes} from '@modules/equipments/types';
import {Path, useForm} from 'react-hook-form';
import {forIn, map, orderBy} from 'lodash';
import {Button, InputAdornment} from '@mui/material';
import {DialogActionRole, DialogType} from '@core/types';
import {useDialogs} from '@core/hooks';
import {SelectControlComponent, TextFieldControlComponent} from '@core/components';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ListIcon from '@mui/icons-material/List';

export const EquipmentsFormComponent = memo((props: EquipmentsFormComponentProps) => {
  const {equipment, onSubmit, saveButtonText, onCancel} = props;
  const {control, handleSubmit, reset, resetField} = useForm<EquipmentForm>({
    mode: 'all',
    defaultValues: {
      color: '',
    },
  });
  const list = [
    {name: 'Твердотельный накопитель', value: EquipmentTypes.ssd},
    {name: 'Жесткий диск', value: EquipmentTypes.hdd},
    {name: 'Монитор', value: EquipmentTypes.monitor},
    {name: 'Видео-карта', value: EquipmentTypes.gpu},
    {name: 'Процессор', value: EquipmentTypes.cpu},
    {name: 'Материнская плата', value: EquipmentTypes.mb},
    {name: 'Блок питания', value: EquipmentTypes.psu},
    {name: 'Форм-фактор', value: EquipmentTypes.device},
  ];
  const colors = map([
    {name: 'По умолчанию', value: ''},
    {name: 'Сланец', value: EquipmentColors.slate},
    {name: 'Серый', value: EquipmentColors.gray},
    {name: 'Цинк', value: EquipmentColors.zinc},
    {name: 'Нейтральный', value: EquipmentColors.neutral},
    {name: 'Камень', value: EquipmentColors.stone},
    {name: 'Красный', value: EquipmentColors.red},
    {name: 'Оранжевый', value: EquipmentColors.orange},
    {name: 'Янтарь', value: EquipmentColors.amber},
    {name: 'Желтый', value: EquipmentColors.yellow},
    {name: 'Лайм', value: EquipmentColors.lime},
    {name: 'Зеленый', value: EquipmentColors.green},
    {name: 'Изумруд', value: EquipmentColors.emerald},
    {name: 'Бирюзовый', value: EquipmentColors.teal},
    {name: 'Голубой', value: EquipmentColors.cyan},
    {name: 'Небо', value: EquipmentColors.sky},
    {name: 'Синий', value: EquipmentColors.blue},
    {name: 'Индиго', value: EquipmentColors.indigo},
    {name: 'Фиолетовый', value: EquipmentColors.violet},
    {name: 'Пурпурный', value: EquipmentColors.purple},
    {name: 'Фуксия', value: EquipmentColors.fuchsia},
    {name: 'Розовый', value: EquipmentColors.pink},
    {name: 'Роза', value: EquipmentColors.rose},
    // {name: 'Черный', value: EquipmentColors.black},
    // {name: 'Белый', value: EquipmentColors.white},
  ], (option) => ({
    ...option,
    name: (
      <div className="flex items-center">
        <span className={`inline-block h-2 w-2 ml-2 mr-3 rounded-full bg-${option.value || EquipmentColors.zinc}-500`}/>
        {option.name}
      </div>
    ),
  }));
  const dialogs = useDialogs();

  useEffect(() => {
    if (!equipment) {
      return;
    }

    forIn(equipment.data, (value: unknown, key: string) => {
      resetField(key as Path<EquipmentForm>, {defaultValue: value as string});
      // setValue(key as keyof EquipmentDocument, value as string);
    });
  }, [equipment, resetField]);

  const handleReset = (): void => {
    dialogs.show({
      title: 'Сбросить',
      message: 'Вы действительно хотите сбросить все изменения?',
      type: DialogType.warning,
      actions: [
        {
          text: 'Сбросить',
          role: DialogActionRole.yes,
          handler: (): void => {
            reset({}, {keepDefaultValues: true});
          },
        },
        {text: 'Отмена'},
      ],
    });
  };

  return (
    <>
      {/* form*/}
      <form
        // onSubmit={handleSubmit((data) => console.log(data))}
        onSubmit={handleSubmit((data) => onSubmit && onSubmit(data))}
        className="flex flex-col h-full"
      >
        <div className="px-6 py-10 bg-white space-y-10 sm:p-10 flex-1">

          {/* name*/}
          <div>
            <TextFieldControlComponent<EquipmentForm>
              control={control}
              name="name"
              label="Название"
              controllerProps={{
                rules: {required: 'Поле обязательно для заполнения'},
              }}
              textFieldProps={{
                autoFocus: true,
                InputProps: {
                  placeholder: 'Имя записи',
                  startAdornment: (<InputAdornment position="start"><ShortTextIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* type*/}
          <div>
            <SelectControlComponent<EquipmentForm>
              control={control}
              name="type"
              controllerProps={{
                rules: {required: 'Необходимо указать тип'},
              }}
              label="Тип"
              list={orderBy(list, 'name')}
              // selectProps={{
              //   displayEmpty: true,
              // }}
              textFieldProps={{
                InputProps: {
                  // placeholder: 'Тип записи',
                  startAdornment: (<InputAdornment position="start"><ListIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* color*/}
          <div>
            <SelectControlComponent<EquipmentForm>
              control={control}
              name="color"
              // controllerProps={{
              //   rules: {required: 'Необходимо указать тип'},
              // }}
              label="Цвет"
              list={orderBy(colors, 'name')}
              selectProps={{
                displayEmpty: true,
              }}
              textFieldProps={{
                // placeholder: 'Цвет текста',
                InputLabelProps: {
                  shrink: true,
                },
                InputProps: {

                  // placeholder: 'Цвет текста',
                  //     startAdornment: (<InputAdornment position="start"><FormatColorTextIcon fontSize="small"/></InputAdornment>),
                  // endAdornment: (
                  //   <InputAdornment position="start" className="mr-4">
                  //     <IconButton onClick={(): void => setValue('color', undefined)}>
                  //       <Close fontSize="small"/>
                  //     </IconButton>
                  //   </InputAdornment>
                  // ),
                },
              }}
            />
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 grid grid-cols-1 md:grid-cols-4 gap-1">
          <Button
            type="button"
            variant="contained"
            disableElevation
            color="secondary"
            className="w-full md:w-auto order-3 md:order-1"
            onClick={handleReset}
          >
            Сбросить
          </Button>

          <Button
            type="reset"
            variant="contained"
            disableElevation
            color="secondary"
            className="w-full md:w-auto order-2 md:order-2"
            onClick={(): void => onCancel && onCancel()}
          >
            Отмена
          </Button>

          <div className="md:hidden h-0 my-2 border-b w-full order-1 md:order-3"/>

          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="w-full md:w-auto md:col-span-2 order-0 md:order-3"
          >
            {saveButtonText ?? 'Сохранить'}
          </Button>
        </div>
      </form>
      {/* end form*/}
    </>
  );
});
