import {doc, DocumentData, DocumentReference, FirestoreDataConverter, getDoc} from 'firebase/firestore';
import {DocumentSnapshot} from '@firebase/firestore';
import {firebaseFirestore, mapConverters} from '@core/firebase';
import {reduce} from 'lodash';

export const wrapDocRecordFields = (data: Record<string, unknown>): Record<string, DocumentReference | string> =>
  reduce(data ?? {}, (acc: Record<string, DocumentReference | string>, value, key) => {
    // if (value) {
    acc[key] = value ? doc(firebaseFirestore, value as string) : '';
    // }
    return acc;
  }, {});

export const wrapGetDocPath = (value: DocumentReference): Promise<DocumentSnapshot> =>
  getDoc(
    doc(firebaseFirestore, value.path).withConverter(mapConverters[value.parent.path] as FirestoreDataConverter<DocumentData>),
  );

export const findRefs = (data: Record<string, DocumentReference | unknown>): Promise<Record<string, DocumentData>> => {
  const fields = Object
    .entries(data)
    .filter(([key, value]) => (value as DocumentReference).type === 'document')
    .map(([key, value]) => wrapGetDocPath(value as DocumentReference).then((doc) => [key, doc.data()]));

  return Promise.all(fields).then((refs) => Object.fromEntries(refs));
};
