import React, {ReactElement} from 'react';
import {Controller, Path, PathValue} from 'react-hook-form';
import {Autocomplete, createFilterOptions, TextField} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {AutocompleteControlComponentProps, AutocompleteControlOption} from '@core/types';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';

export const AutocompleteControlComponent = <T, >(props: AutocompleteControlComponentProps<T>): ReactElement => {
  const {control, disabled, options, name, label, onAdd, controllerProps, textFieldProps = {}} = props;
  const filter = createFilterOptions<AutocompleteControlOption>();

  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      // rules={{required: 'Необходимо указать тип'}}
      defaultValue={'' as UnpackNestedValue<PathValue<T, Path<T>>>}
      render={({field, fieldState}): ReactElement =>
        <Autocomplete
          {...field}
          disabled={disabled}
          options={options}
          isOptionEqualToValue={(option, value): boolean => option.path === value.path}
          renderInput={(params): ReactElement =>
            <TextField
              {...textFieldProps}
              {...params}
              error={fieldState.invalid}
              label={label}
              helperText={fieldState.error?.message}
              InputProps={{
                ...params.InputProps,
                ...textFieldProps.InputProps,
              }}
            />
          }
          onChange={(e, value): void => {
            field.onChange(value?.path ?? '');

            if (value?.id === '_id_') {
              onAdd && onAdd(value, name);
            }
          }}
          noOptionsText="Нет опций"
          clearText=""
          openText=""
          value={options.find((item) => item.path === field.value) ?? null}
          getOptionLabel={(option): string => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.id === '_id_') {
              return '';
            }

            return option.label;
          }}
          renderOption={(props, option, {inputValue}): ReactElement => (
            <li {...props}>
              <div>
                {parse(option.label, match(option.label, inputValue)).map((part, index) => (
                  <span key={index} className={part.highlight ? 'font-bold' : 'font-normal'}>{part.text}</span>
                ))}
              </div>
            </li>
          )}
          filterOptions={(options, params): AutocompleteControlOption[] => {
            const filtered = filter(options, params);

            if (onAdd) {
              const {inputValue} = params;
              const isExisting = options.some((option) => inputValue === option.label);

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  label: `Добавить "${inputValue}"`,
                  path: '_path_',
                  id: '_id_',
                  name: inputValue,
                });
              }
            }

            return filtered;
          }}
        />
      }
    />
  );
};
