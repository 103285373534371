import React, {memo} from 'react';
import {Outlet} from 'react-router-dom';

export const ClearLayout = memo(() => {
  return (
    <div className="min-h-full h-full flex flex-col">
      {/* <Header/>*/}
      <Outlet/>
    </div>
  );
});
