import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {getPerformance} from 'firebase/performance';
import {connectFirestoreEmulator, FirestoreDataConverter, getFirestore} from 'firebase/firestore';
import {entitiesConverterFirestore} from '@modules/entities/firestore';
import {equipmentsConverterFirestore} from '@modules/equipments/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const perf = getPerformance(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);

if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST && process.env.REACT_APP_FIRESTORE_EMULATOR_PORT) {
  connectFirestoreEmulator(
    firebaseFirestore,
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    Number(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT),
  );
}

if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
  connectAuthEmulator(
    firebaseAuth,
    process.env.REACT_APP_AUTH_EMULATOR_URL,
  );
}

export const mapConverters: Record<string, FirestoreDataConverter<unknown>> = {
  entities: entitiesConverterFirestore,
  equipments: equipmentsConverterFirestore,
};
