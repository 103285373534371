import React, {memo, MouseEvent, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Gravatar from 'react-gravatar';
import {useAuth} from '@core/hooks';

export const DefaultLayout = memo(() => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const settings = [
    {name: 'Выход', action: (): void => auth.signout()},
  ];
  const pages = [
    {name: 'Техника', href: '/entities', current: location.pathname === '/entities'},
    {name: 'Реестр', href: '/equipments', current: location.pathname === '/equipments'},
  ];

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (action?: () => void): void => {
    setAnchorElUser(null);
    action && action();
  };

  return (
    <div className="min-h-full h-full flex flex-col">
      <AppBar position="static" className="bg-zinc-500">
        <div className="max-w-full mx-auto w-full px-4 sm:px-6 lg:px-8">
          <Toolbar disableGutters className="">
            <div className="hidden md:flex">
              <img className="max-w-max" src="/logo.svg" alt="maybe.equipment"/>
              <span className="hidden sm:block w-full relative text-orange-500 font-bold relative -left-12 text-xl">
                equipment
              </span>
            </div>

            <div className="flex-grow flex md:hidden">
              <IconButton
                size="large"
                // aria-label="account of current user"
                // aria-controls="menu-appbar"
                // aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon/>
              </IconButton>
              <Menu
                // id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                className="block md:hidden"
              >
                {pages.map((page) => (
                  <MenuItem key={page.href} onClick={handleCloseNavMenu} className="w-48">
                    <Link to={page.href}>
                      {page.name}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>

            <div className="flex md:hidden flex-grow font-bold text-xl">
              <span className="text-white">
                maybe.
              </span>
              <span className="text-orange-500">
                equipment
              </span>
            </div>

            <div className="flex-grow hidden md:flex">
              {pages.map((page) => (
                <Button
                  key={page.href}
                  onClick={(): void => navigate(page.href)}
                  className={`${page.current ? 'text-orange-500' : 'text-white'} h-full hover:bg-zinc-900 block m-0 py-5 rounded-none`}
                >
                  {page.name}
                </Button>
              ))}
            </div>

            <div className="flex-grow-0">
              <IconButton onClick={handleOpenUserMenu} className="p-0">
                {auth.user && <Gravatar className="h-10 w-10 rounded-full" email={auth.user?.email as string}/>}
              </IconButton>
              <Menu
                className="mt-11"
                // id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={(): void => handleCloseUserMenu()}
                MenuListProps={{
                  className: 'py-0',
                }}
              >
                <div
                  onClick={(): void => handleCloseUserMenu()}
                  className="w-60 bg-gray-200 p-4 truncate font-medium"
                >
                  {auth.user?.email}
                </div>
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={(): void => handleCloseUserMenu(setting.action)}
                    className="w-60 truncate p-4"
                  >
                    {setting.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Toolbar>
        </div>
      </AppBar>

      <Outlet/>
    </div>
  );
});
