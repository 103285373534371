import {EntitiesRef, Entity, EntityDocument, EntityDocumentFields, EntityDocumentRelationsFields} from '@modules/entities/types';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from 'firebase/firestore';
import {escapeRegExp, findRefs, preparation} from '@core/utils';

export const entitiesConverterFirestore = {
  toFirestore(item: Entity): DocumentData {
    return preparation(item.data, Object.values(EntityDocumentFields));
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Entity {
    const data = preparation(snapshot.data(options), Object.values(EntityDocumentFields));
    const relations = preparation(snapshot.data(options), Object.values(EntityDocumentRelationsFields));

    return new (
      class implements Entity {
        public constructor(
          public id: string,
          public data: EntityDocument,
          public snapshot: QueryDocumentSnapshot,
          public refs: EntitiesRef,
        ) {
        }

        public toString(): string {
          return this.id;
        }

        public withRefs(): Promise<Entity> {
          return findRefs(relations).then((refs) => {
            this.refs = {...this.refs, ...refs as EntitiesRef};
            return this;
          });
        }

        public search(search: string): boolean {
          const searchRegex = new RegExp(escapeRegExp(search), 'i');

          return Object.values(this.data).filter((value) => value !== null).some((value) =>
              searchRegex.test(value.toString()),
            ) ||
            Object.values(this.refs ?? {}).filter((value) => value.data.name !== null).some((value) =>
              searchRegex.test(value.data.name.toString()),
            );
        }
      }
    )(snapshot.id, data, snapshot, {});
  },
};
