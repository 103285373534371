import {useCollection} from 'react-firebase-hooks/firestore';
import {collection, FirestoreDataConverter, orderBy, query} from 'firebase/firestore';
import {firebaseFirestore, mapConverters} from '@core/firebase';
import {useEffect, useState} from 'react';
import {EntitiesList, Entity} from '@modules/entities/types';
import {useEquipmentsList} from '@modules/equipments/hooks';

export const useEntitiesList = (): EntitiesList => {
  const [list, setList] = useState<Entity[]>();
  const [value, loading, error] = useCollection(
    query(
      collection(firebaseFirestore, 'entities').withConverter(mapConverters.entities as FirestoreDataConverter<Entity>),
      orderBy('createAt', 'desc'),
    ),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );
  const [equipments] = useEquipmentsList();

  // list
  useEffect(() => {
    if (!value || !equipments) {
      return;
    }

    Promise
      .all(value.docs.map((doc) => doc.data().withRefs()))
      .then((docs) => setList(docs));
  }, [value, equipments]);

  return [list, loading, error];
};
