import {doc, setDoc} from 'firebase/firestore';
import {useCallback, useState} from 'react';
import {generateUniqueId, preparation, wrapDocRecordFields} from '@core/utils';
import {firebaseFirestore} from '@core/firebase';
import {EntitiesCreate, EntityDocumentFields, EntityDocumentRelationsFields, EntityForm} from '@modules/entities/types';

export const useEntitiesCreate = (): EntitiesCreate => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lastId, setLastId] = useState<string>();

  const create = useCallback(
    (data: EntityForm) => {
      const id = generateUniqueId();
      const date = new Date().toLocaleString();
      const fields = Object.values(EntityDocumentFields);
      const relations = Object.values(EntityDocumentRelationsFields);
      const document = {
        ...preparation(data, fields, {
          createAt: date,
          updateAt: date,
        }),
        ...wrapDocRecordFields(preparation(data, relations)),
      };
      const ref = doc(firebaseFirestore, 'entities', id);

      setLoading(true);
      setLastId(id);

      return setDoc(ref, document).then(() => {
        setLoading(false);
        return {id, document, ref};
      });
    },
    [],
  );

  return [create, loading, lastId];
};
