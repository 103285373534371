import React, {memo, ReactElement} from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';
import {EntitiesRamControlComponentProps} from '@modules/entities/types';
import {fill} from 'lodash';

export const EntitiesRamControlComponent = memo((props: EntitiesRamControlComponentProps): ReactElement => {
  const {control, controllerProps, name, label, selectProps} = props;

  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      // rules={{required: 'Необходимо указать тип'}}
      defaultValue={[0, 0]}
      render={({field, fieldState, formState}): ReactElement =>
        <div className={`grid grid-cols-${(field.value as number[]).length + 1} gap-x-3`}>
          <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
              {...selectProps}
              error={fieldState.invalid}
              label={label}
              value={field.value?.length || 2}
              displayEmpty={false}
              onChange={(e): void => {
                const slots = e.target.value;
                const value = Array
                  .from(Array(slots))
                  .map((v, i) => Number((field.value ?? [])[i] ?? 0));

                field.onChange({target: {value}});
              }}
            >
              {/* <MenuItem className="text-gray-500" value={''}>Не задано</MenuItem>*/}
              {
                [1, 2, 4].map((name) =>
                  <MenuItem key={name} value={name}>{name}</MenuItem>)
              }
            </Select>
          </FormControl>

          {Array.from(Array((field.value as number[]).length)).map((v, i) =>
            <FormControl key={i}>
              <InputLabel>{i + 1}</InputLabel>
              <Select
                error={fieldState.invalid}
                label={i + 1}
                value={(field.value ?? [])[i] ?? 0}
                onChange={(e): void => {
                  const value = fill(field.value as string ?? [], e.target.value, i, i + 1);
                  field.onChange({target: {value}});
                }}
              >
                {/* <MenuItem className="text-gray-500" value={''}>Не задано</MenuItem>*/}
                {
                  [0, 2, 4, 8, 16].map((name) =>
                    <MenuItem key={`${i}-${name}`} value={name}>{name}</MenuItem>)
                }
              </Select>
            </FormControl>,
          )}
          <FormHelperText error={fieldState.invalid}>
            {fieldState.error?.message}
          </FormHelperText>
        </div>
      }
    />
  );
});
