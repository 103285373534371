import React, {ReactElement} from 'react';
import {useRoutes} from 'react-router-dom';
import {useAuth} from '@core/hooks';
import {appRoutes} from './app.routes';

// ReactElement<unknown, string | JSXElementConstructor<unknown>>
function App(): ReactElement {
  const auth = useAuth();
  const routes = useRoutes(appRoutes);

  return (
    <>
      {auth?.loading ?
        <div className="w-full text-center h-full items-center flex flex-row">
          <span className="flex-1">Загрузка...</span>
        </div> : routes
      }
    </>
  );
}

export default App;
