import React, {ComponentType, StrictMode} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {DialogProvider} from '@core/providers';
import {SnackbarProvider, SnackbarProviderProps} from 'notistack';
import {Slide, ThemeProvider} from '@mui/material';
import {appMuiTheme, snackbarClasses} from './app.theme';
import ComposeProvider from '@core/providers/compose.provider';
import {ThemeProviderProps} from '@mui/material/styles/ThemeProvider';

ReactDOM.render(
  <ComposeProvider
    items={[
      [StrictMode],
      [BrowserRouter],
      [ThemeProvider, {theme: appMuiTheme} as ThemeProviderProps],
      [SnackbarProvider, {
        maxSnack: 10,
        classes: snackbarClasses,
        TransitionComponent: Slide as ComponentType,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      } as SnackbarProviderProps],
      [DialogProvider],
    ]}
  >
    <App/>
  </ComposeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
