import {doc, updateDoc} from 'firebase/firestore';
import {useCallback, useState} from 'react';
import {EquipmentDocumentFields, EquipmentForm, EquipmentsUpdateHook} from '@modules/equipments/types';
import {firebaseFirestore} from '@core/firebase';
import {preparation} from '@core/utils';

export const useEquipmentsUpdate = (): EquipmentsUpdateHook => {
  const [loading, setLoading] = useState<boolean>(false);

  const update = useCallback(
    (id, data: EquipmentForm) => {
      const updateAt = new Date().toLocaleString();
      const fields = Object.values(EquipmentDocumentFields);
      const document = preparation(data, fields, {
        updateAt,
      });
      const ref = doc(firebaseFirestore, 'equipments', id);

      setLoading(true);

      return updateDoc(ref, document).then(() => {
        setLoading(false);

        return {id, document, ref};
      });
    },
    [],
  );

  return [update, loading];
};
