import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from 'firebase/firestore';
import {escapeRegExp, preparation} from '@core/utils';
import {Equipment, EquipmentDocument, EquipmentDocumentFields} from '@modules/equipments/types';

export const equipmentsConverterFirestore = {
  toFirestore(item: Equipment): DocumentData {
    return preparation(item.data, Object.values(EquipmentDocumentFields));
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Equipment {
    const data = snapshot.data(options)!;

    return new (
      class implements Equipment {
        public constructor(
          public id: string,
          public data: EquipmentDocument,
          public snapshot: QueryDocumentSnapshot,
        ) {
        }

        public toString(): string {
          return this.id;
        }

        public search(search: string): boolean {
          const searchRegex = new RegExp(escapeRegExp(search), 'i');

          return Object.values(this.data).filter((value) => value !== null).some((value) =>
            searchRegex.test(value.toString()),
          );
        }
      }
    )(snapshot.id, data, snapshot);
  },
};
