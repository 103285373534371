import {Navigate, useLocation} from 'react-router-dom';
import React, {ReactElement} from 'react';
import {useAuth} from '@core/hooks';

export function RequireAuth({children}: { children: JSX.Element }): ReactElement {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{from: location}} replace/>;
  }

  return children;
}

export function RequireGuest({children}: { children: JSX.Element }): ReactElement {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/" state={{from: location}} replace/>;
  }

  return children;
}
