import {doc, setDoc} from 'firebase/firestore';
import {useCallback, useState} from 'react';
import {EquipmentDocumentFields, EquipmentForm, EquipmentsCreate} from '@modules/equipments/types';
import {generateUniqueId, preparation} from '@core/utils';
import {firebaseFirestore} from '@core/firebase';

export const useEquipmentsCreate = (): EquipmentsCreate => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lastId, setLastId] = useState<string>();

  const create = useCallback(
    (data: EquipmentForm) => {
      const id = generateUniqueId();
      const date = new Date().toLocaleString();
      const document = preparation(data, Object.values(EquipmentDocumentFields), {
        createAt: date,
        updateAt: date,
      });
      const ref = doc(firebaseFirestore, 'equipments', id);

      setLoading(true);
      setLastId(id);

      return setDoc(ref, document).then(() => {
        setLoading(false);
        return {id, document, ref};
      });
    },
    [],
  );

  return [create, loading, lastId];
};
