import React, {memo, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useEquipmentsItem, useEquipmentsUpdate} from '@modules/equipments/hooks';
import {EquipmentsFormComponent} from '@modules/equipments/components';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useSnackbar} from 'notistack';
import {EquipmentForm} from '@modules/equipments/types';

export const EquipmentsEditPage = memo(() => {
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [equipment, loading, , exists] = useEquipmentsItem(String(id));
  const [update] = useEquipmentsUpdate();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (id && !loading && !exists) {
      navigate('/404');
    }
  }, [loading, exists, id, navigate]);

  const navigateToList = (): void => {
    navigate('/equipments');
  };

  const handleSubmit = (data: EquipmentForm): void => {
    if (!id || !data) {
      return;
    }

    update(id, data).then(() => {
      enqueueSnackbar(
        `Запись "${id}" успешно обновлена`,
        {variant: 'success'},
      );
      // navigate('/equipments');
      navigateToList();
    });
  };

  return (
    <>
      <div className="bg-gray-50 p-6 sm:py-6 sm:px-10">
        <div className="flex">
          <Typography variant="h5" className="text-gray-900 flex-1 truncate">{equipment?.id}</Typography>
          <Tooltip title="Закрыть">
            <IconButton onClick={navigateToList}>
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Редактирование записи</p>
      </div>

      <div className="relative flex-1">
        <EquipmentsFormComponent
          saveButtonText="Сохранить"
          equipment={equipment}
          onSubmit={handleSubmit}
          onCancel={navigateToList}
        />
      </div>

    </>
  );
});
