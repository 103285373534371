import {Navigate} from 'react-router-dom';
import {ClearLayout, DefaultLayout} from './layouts';
import {RequireAuth, RequireGuest} from '@modules/auth/routers';
import {AuthLoginPage} from '@modules/auth/pages';
import {EntitiesCreatePage, EntitiesDetailPage, EntitiesEditPage, EntitiesListPage} from '@modules/entities/pages';
import {EquipmentsCreatePage, EquipmentsEditPage, EquipmentsListPage} from '@modules/equipments/pages';
import {E404Page} from '@core/pages';
import React from 'react';

export const appRoutes = [
  // home
  {
    path: '/',
    element: <Navigate to="/entities"/>,
  },

  // gust, clear layout
  {
    element: <ClearLayout/>,
    children: [
      {
        path: '/login',
        element: <RequireGuest><AuthLoginPage/></RequireGuest>,
      },
      {
        path: '/entities/:id',
        element: <EntitiesDetailPage/>,
      },
    ],
  },

  // auth, default layout
  {
    element: <RequireAuth><DefaultLayout/></RequireAuth>,
    children: [
      // entities
      {
        path: '/entities',
        element: <EntitiesListPage/>,
        children: [
          {
            path: 'create',
            element: <EntitiesCreatePage/>,
          },
          {
            path: ':id/edit',
            element: <EntitiesEditPage/>,
          },
        ],
      },

      // equipments
      {
        path: '/equipments',
        element: <EquipmentsListPage/>,
        children: [
          {
            path: 'create',
            element: <EquipmentsCreatePage/>,
          },
          {
            path: ':id/edit',
            element: <EquipmentsEditPage/>,
          },
        ],
      },
    ],
  },

  // not found
  {
    element: <ClearLayout/>,
    children: [
      {
        path: '/404',
        element: <E404Page/>,
      },
      {
        path: '*',
        element: <E404Page/>,
      },
    ],
  },
];
