import {useDocumentOnce} from 'react-firebase-hooks/firestore';
import {doc, FirestoreDataConverter} from 'firebase/firestore';
import {firebaseFirestore, mapConverters} from '@core/firebase';
import {useEffect, useState} from 'react';
import {EntitiesItem, Entity} from '@modules/entities/types';
import {useEquipmentsList} from '@modules/equipments/hooks';

export const useEntitiesItem = (id: string): EntitiesItem => {
  const [item, setItem] = useState<Entity>();
  const [exists, setExists] = useState<boolean>(true);
  const [value, loading, error] = useDocumentOnce(
    doc(firebaseFirestore, 'entities', id).withConverter(mapConverters.entities as FirestoreDataConverter<Entity>),
  );
  const [equipments] = useEquipmentsList();

  // item
  useEffect(() => {
    if (!value || !equipments) {
      return;
    }

    const data = value.data();

    if (data) {
      data.withRefs().then((entity) => setItem(entity));
    }

    setExists(value.exists());
  }, [value, equipments]);

  return [item, loading, error, exists];
};
