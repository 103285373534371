/* eslint-disable no-unused-vars */

import {Control, Path} from 'react-hook-form';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {UseControllerProps} from 'react-hook-form/dist/types/controller';
import {SelectProps} from '@mui/material/Select/Select';
import {ReactElement} from 'react';

export enum DialogType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export enum DialogActionRole {
  yes = 'yes',
  cancel = 'cancel',
}

export interface DialogAction {
  text: string;
  data?: Record<string, unknown>;
  role?: DialogActionRole;
  handler?: (data: Record<string, unknown>) => void;
}

export interface Dialog {
  id: string;
  type: DialogType;
  title: string;
  message: string;
  actions?: DialogAction[];
}

export interface DialogConfig {
  id?: string;
  type?: DialogType;
  title: string;
  message: string;
  actions?: DialogAction[];
}

export interface DialogComponentProps {
  type: DialogType;
  title: string;
  message: string;
  onClose?: () => void;
  actions?: DialogAction[];
}

export interface SelectControlComponentProps<T> {
  control: Control<T>;
  name: Path<T>;
  list: SelectControlOption[];
  label: string;
  controllerProps?: Partial<UseControllerProps>;
  selectProps?: Partial<SelectProps>;
  textFieldProps?: Partial<TextFieldProps>;
}

export interface SelectControlOption {
  // path: string;
  // label: string;
  name: string | ReactElement;
  value: string;
}

export interface TextFieldControlComponentProps<T> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  controllerProps?: Partial<UseControllerProps>;
  textFieldProps?: Partial<TextFieldProps>;
}

export interface AutocompleteControlComponentProps<T> {
  control: Control<T>;
  disabled?: boolean;
  options: AutocompleteControlOption[];
  name: Path<T>;
  label: string;
  controllerProps?: Partial<UseControllerProps>;
  textFieldProps?: Partial<TextFieldProps>;
  // autocompleteProps?: AutocompleteProps<EntitiesSelectOption, undefined, undefined, undefined>,
  // textFieldProps?: TextFieldProps;
  onAdd?: (data: AutocompleteControlOption, name: Path<T>) => void;
}

export interface AutocompleteControlOption {
  path: string;
  label: string;
  name: string;
  id: string;
}
