import {deleteDoc, doc} from 'firebase/firestore';
import {useCallback, useState} from 'react';
import {firebaseFirestore} from '@core/firebase';
import {EntitiesRemoveHook} from '@modules/entities/types';

export const useEntitiesRemove = (): EntitiesRemoveHook => {
  const [loading, setLoading] = useState<boolean>(false);

  const remove = useCallback(
    (id) => {
      setLoading(true);

      return deleteDoc(doc(firebaseFirestore, 'entities', id)).then(() => {
        setLoading(false);
        return true;
      });
    },
    [],
  );

  return [remove, loading];
};
