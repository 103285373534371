import React, {ChangeEvent, memo, useEffect, useState} from 'react';
import {DialogActionRole, DialogType} from '@core/types';
import {createSearchParams, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Equipment, EquipmentsPageListColumn} from '@modules/equipments/types';
import {useEquipmentsList, useEquipmentsRemove} from '@modules/equipments/hooks';
import {
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import {Close, PostAdd, Search} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {get, orderBy} from 'lodash';
import {useSnackbar} from 'notistack';
import {useDialogs} from '@core/hooks';

export const EquipmentsListPage = memo(() => {
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const columns: EquipmentsPageListColumn[] = [
    {name: 'ID', value: 'id', order: 'id'},
    {name: 'Название', value: 'data.name', order: 'data.name'},
    {name: 'Тип', value: 'data.type', order: 'data.type'},
  ];
  const [equipments, loading] = useEquipmentsList();
  const [filtered, setFiltered] = useState<Equipment[]>([]);
  const [search, setSearch] = useState(query.get('search') ?? '');
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [order, setOrder] = useState<string>('createAt');
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('desc');
  const [remove] = useEquipmentsRemove();
  const {id} = useParams<{ id: string }>();
  const {enqueueSnackbar} = useSnackbar();

  // edit panel state
  useEffect(() => {
    const state = !!id || location.pathname.includes('create');

    setOpenEditPanel(state);
  }, [id, location]);

  useEffect(() => {
    if (id || location.pathname.includes('create')) {
      return;
    }

    navigate({
      pathname: '/equipments',
      search: search ? `?${createSearchParams({search: search})}` : '',
    });
  }, [openEditPanel, search, id, navigate, location.pathname]);

  // equipments list and search text
  useEffect(() => {
    if (!equipments?.length) {
      return;
    }

    setFiltered(
      orderBy(
        equipments.filter((row: Equipment) => row.search(search)),
        [order],
        [orderDir],
      ),
    );
  }, [equipments, search, order, orderDir]);

  // action "delete" for row in table
  const handleDeleteEquipments = (item: Equipment): void => {
    if (!item) {
      return;
    }

    dialogs.show({
      title: 'Удалить запись',
      message: `Вы действительно хотите удалить запись "${item.data.name}" (id: ${item.id})?`,
      type: DialogType.warning,
      actions: [
        {
          text: 'Удалить',
          role: DialogActionRole.yes,
          handler: (): void => {
            if (!item.id) {
              return;
            }

            remove(item.id).then(() => enqueueSnackbar(
              `Запись "${item.id}" успешно удалена`,
              {variant: 'success'},
            ));
          },
        },
        {text: 'Отмена'},
      ],
    });
  };

  const handleOrder = (column: EquipmentsPageListColumn): void => {
    if (!column.order) {
      return;
    }

    if (orderDir === 'asc') {
      return setOrderDir('desc');
    }

    setOrder(column.order);
    setOrderDir('asc');
  };

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto">
        <header className="bg-white border-b">
          <div className="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
            {/* <h1 className="text-3xl font-bold text-gray-900">Техника</h1>*/}
            <div className="flex items-center justify-between flex-col">
              <div className="flex-1 w-full mb-4">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  Реестр
                </h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="flex items-center text-gray-500">
                    Записей: {filtered?.length ?? 0}
                  </div>
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                  {/* <div className="mt-2 flex items-center text-sm text-gray-500">*/}
                  {/*  text*/}
                  {/* </div>*/}
                </div>
              </div>

              <div className="w-full flex">
                <OutlinedInput
                  fullWidth
                  placeholder="Поиск..."
                  value={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value)}
                  className="flex-1 mr-4"
                  inputProps={{
                    className: 'py-2 leading-7 h-7',
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search/>
                    </InputAdornment>
                  }
                  endAdornment={
                    search &&
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={(): void => setSearch('')}>
                        <Close fontSize="small"/>
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* </div> */}

                {/* <div className="mt-5 flex lg:mt-0 lg:ml-4 justify-end"> */}
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={(): void => navigate('/equipments/create')}
                  startIcon={<PostAdd/>}
                  className="py-2 leading-7"
                >
                  Создать
                </Button>
              </div>
            </div>
          </div>
        </header>

        <main className="sm:overflow-auto flex-1">
          <div className="max-w-full mx-auto sm:py-6 sm:px-6 lg:px-8">

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) =>
                      <TableCell
                        className="w-1/5"
                        // className="font-bold text-gray-500 uppercase tracking-wider"
                        // size="small"
                        key={column.name}
                      >
                        <TableSortLabel
                          // hideSortIcon={!column.order?.length}
                          active={(column.order ?? '') === order}
                          direction={orderDir}
                          onClick={(): void => handleOrder(column)}
                          classes={{active: 'text-orange-500'}}
                        >
                          {column.name}
                        </TableSortLabel>
                      </TableCell>,
                    )}
                    <TableCell className="w-1/5">Цвет</TableCell>
                    <TableCell className="w-1/5"/>
                  </TableRow>
                </TableHead>
                <TableBody className="divide-y divide-gray-50">
                  {filtered.length ? filtered.map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        // className="hover:bg-gray-50"
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {columns.map((column) =>
                          <TableCell
                            key={`${row.id}-${column.name}`}
                            className="border-0"
                          >
                            {get(row, column.value)}
                          </TableCell>,
                        )}

                        <TableCell
                          className="border-0"
                        >
                          <span className={`inline-block h-2 w-6 mr-2 rounded-full bg-${row.data.color || 'zinc'}-500`}/>
                        </TableCell>

                        <TableCell align="right" className="whitespace-nowrap border-0">
                          <Tooltip title="Редактировать">
                            <div
                              className="cursor-pointer w-10 h-10 p-2 flex-0 inline-flex text-gray-500 hover:text-gray-900"
                              onClick={(): void => navigate(`/equipments/${row.id}/edit`)}
                            >
                              <EditIcon/>
                            </div>
                          </Tooltip>

                          <Tooltip title="Удалить">
                            <div
                              className="cursor-pointer w-10 h-10 p-2 flex-0 inline-flex text-gray-500 hover:text-gray-900"
                              onClick={(): void => handleDeleteEquipments(row)}
                            >
                              <DeleteIcon/>
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )) :
                    <TableRow>
                      <TableCell align="center" colSpan={columns.length + 2}>
                        {loading ? 'Загрузка' : 'Нет данных'}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Drawer
            anchor="right"
            open={openEditPanel}
            onClose={(): void => navigate('/equipments')}
            PaperProps={{className: 'w-full md:w-160 h-full'}}
          >
            <div
              className="h-full flex flex-col"
              role="presentation"
            >
              <Outlet/>
            </div>
          </Drawer>
        </main>
      </div>
    </>
  );
});
