import {reduce} from 'lodash';

export const preparation = <T, R extends Partial<T>>(data: T, fields: string[], assign?: Record<string, unknown>): R => {
  // const fields = Object.values(schema);

  return {
    // allow only fields from schema
    ...reduce(data ?? {}, (acc: Record<string, unknown>, value: unknown, key: string) => {
      if (fields.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {}),

    // assign data
    ...(assign ?? {}),
  } as R;
};

export const relations = <T>(data: T, dict: Record<string, unknown>, refFields: string[]): Record<string, unknown> => {
  // const refFields = Object.values(schema);

  return reduce(data ?? {}, (acc: Record<string, unknown>, value: unknown, key: string) => {
    if (refFields.includes(key) && dict[value as string]) {
      acc[key] = dict[value as string];
    }
    return acc;
  }, {});
};
