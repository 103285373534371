import React, {ReactElement} from 'react';

export const E404Page = (): ReactElement => {
  return (
    <>
      <div className="w-full text-center h-full items-center flex flex-row py-6 text-gray-500 text-7xl">
        <span className="flex-1">404</span>
      </div>
    </>
  );
};
