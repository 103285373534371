import {doc, updateDoc} from 'firebase/firestore';
import {useCallback, useState} from 'react';
import {firebaseFirestore} from '@core/firebase';
import {EntitiesUpdateHook, EntityDocumentFields, EntityDocumentRelationsFields, EntityForm} from '@modules/entities/types';
import {preparation, wrapDocRecordFields} from '@core/utils';

export const useEntitiesUpdate = (): EntitiesUpdateHook => {
  const [loading, setLoading] = useState<boolean>(false);

  const update = useCallback(
    (id, data: EntityForm) => {
      const updateAt = new Date().toLocaleString();
      const fields = Object.values(EntityDocumentFields);
      const relations = Object.values(EntityDocumentRelationsFields);
      const document = {
        ...preparation(data, fields, {
          updateAt,
        }),
        ...wrapDocRecordFields(preparation(data, relations)),
      };
      const ref = doc(firebaseFirestore, 'entities', id);

      setLoading(true);

      return updateDoc(ref, document).then(() => {
        setLoading(false);

        return {id, document: data, ref};
      });
    },
    [],
  );

  return [update, loading];
};

