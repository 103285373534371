import React, {ReactElement} from 'react';
import {Controller, Path, PathValue} from 'react-hook-form';
import {TextField} from '@mui/material';
import {TextFieldControlComponentProps} from '@core/types';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';

export const TextFieldControlComponent = <T, >(props: TextFieldControlComponentProps<T>): ReactElement => {
  const {control, controllerProps, name, textFieldProps, label} = props;

  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      // rules={{required: 'Поле обязательно для заполнения'}}
      defaultValue={'' as UnpackNestedValue<PathValue<T, Path<T>>>}
      render={({field, fieldState}): ReactElement =>
        <TextField
          {...textFieldProps}
          {...field}
          fullWidth
          // disabled={disabled}
          error={fieldState.invalid}
          label={label}
          variant="outlined"
          helperText={fieldState.error?.message ?? textFieldProps?.helperText}
        />
      }
    />
  );
};
