import React, {ReactElement} from 'react';
import {MenuItem, TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import {SelectControlComponentProps} from '@core/types';

export const SelectControlComponent = <T, >(props: SelectControlComponentProps<T>): ReactElement => {
  const {control, controllerProps, name, textFieldProps, list, label, selectProps} = props;

  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      // rules={{required: 'Необходимо указать тип'}}
      render={({field, fieldState}): ReactElement =>
        <TextField
          {...textFieldProps}
          {...field}
          select
          SelectProps={{...selectProps}}
          fullWidth
          error={fieldState.invalid}
          label={label}
          value={field.value || ''}
          helperText={fieldState.error?.message ?? textFieldProps?.helperText}
        >
          {
            list.map(({name, value}) =>
              <MenuItem key={value} value={value}>{name}</MenuItem>)
          }
        </TextField>
      }
    />
  );
};
