import React, {createContext, PropsWithChildren, ReactElement, useCallback, useState} from 'react';
import {Dialog, DialogConfig, DialogsType, DialogType} from '@core/types';
import {generateUniqueId} from '@core/utils';
import {DialogComponent} from '@core/components';

export const DialogContext = createContext<DialogsType>(null!);

export const DialogProvider = ({children}: PropsWithChildren<{}>): ReactElement => {
  const [list, setDialogs] = useState<Dialog[]>([]);

  const show = useCallback(
    (config: DialogConfig) => {
      const data = {
        ...config,
        id: config.id ?? generateUniqueId(),
        type: config.type ?? DialogType.info,
      };

      setDialogs((prevState) => [...prevState, data]);
    },
    [],
  );

  const closeTop = useCallback(
    () => {
      setDialogs((prevState) => {
        const arr = [...prevState];

        arr.shift();

        return arr;
      });
    },
    [],
  );

  return (
    <DialogContext.Provider value={{show, closeTop, list}}>
      {children}
      {list[0] &&
        <DialogComponent
          key={list[0].id}
          onClose={(): void => closeTop()}
          {...list[0]}
        />
      }
    </DialogContext.Provider>
  );
};
