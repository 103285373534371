export const descendingComparator = (a: string, b: string): number => {
  if (b < a) {
    return -1;
  }

  if (b > a) {
    return 1;
  }

  return 0;
};

export const emptyComparator = (a: string, b: string): number | undefined => {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }
};
