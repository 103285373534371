import {marked} from 'marked';

export const truncate = (value: string, limit = 25, completeWords = false, ellipsis = '...'): string => {
  if (!value) {
    return value;
  }

  if (completeWords) {
    limit = value.substr(0, limit).lastIndexOf(' ');
  }

  return value.length > limit ? value.substr(0, limit) + ellipsis : value;
};

export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const generateUniqueId = (): string => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 6; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const getMarkdownText = (text: string): { __html: string } => {
  const rawMarkup = marked.parse(text);
  return {__html: rawMarkup};
};
