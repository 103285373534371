import React, {memo, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useEntitiesItem, useEntitiesUpdate} from '@modules/entities/hooks';
import {EntityDocument} from '@modules/entities/types';
import {EntitiesFormComponent} from '@modules/entities/components';
import {useSnackbar} from 'notistack';

export const EntitiesEditPage = memo(() => {
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [entity, loading, , exists] = useEntitiesItem(String(id));
  const [update] = useEntitiesUpdate();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (id && !loading && !exists) {
      navigate('/404');
    }
  }, [loading, exists, id, navigate]);

  const navigateToList = (): void => {
    navigate('/entities');
  };

  const handleSubmit = (data: EntityDocument): void => {
    if (!id || !data) {
      return;
    }

    update(id, data).then(() => {
      enqueueSnackbar(
        `Запись "${id}" успешно обновлена`,
        {variant: 'success'},
      );
      // navigate('/entities');
      navigateToList();
    });
  };

  return (
    <>
      <div className="bg-gray-50 p-6 sm:py-6 sm:px-10">
        <div className="flex">
          <Typography variant="h5" className="text-gray-900 flex-1 truncate">{entity?.id}</Typography>
          <Tooltip title="Закрыть">
            <IconButton onClick={navigateToList}>
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Редактирование записи</p>
      </div>

      <div className="relative flex-1">
        <EntitiesFormComponent
          saveButtonText="Сохранить"
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={navigateToList}
        />
      </div>

    </>
  );
});
