// const colors = require('tailwindcss/colors');
const plugin = require('tailwindcss/plugin');

/** @type {import('@types/tailwindcss/tailwind-config').TailwindConfig } */
module.exports = {
  darkMode: 'class',
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  safelist: [
    'grid-cols-5',
    'grid-cols-3',
    'grid-cols-2',
    {
      pattern: /^(bg|text|ring)-/,
      variants: ['hover', 'focus'],
    },
  ],
  important: true,
  // important: '#root',
  theme: {
    extend: {
      fontSize: {
        'xs': '0.625rem',
        'sm': '0.75rem',
        'md': '0.8125rem',
        'base': '0.875rem',
        'lg': '1rem',
        'xl': '1.125rem',
        '2xl': '1.25rem',
        '3xl': '1.5rem',
        '4xl': '2rem',
        '5xl': '2.25rem',
        '6xl': '2.5rem',
        '7xl': '3rem',
        '8xl': '4rem',
        '9xl': '6rem',
        '10xl': '8rem',
      },
      spacing: {
        '34': '8.5rem',

        '100': '25rem',
        '120': '30rem',
        '128': '32rem',
        '140': '35rem',
        '160': '40rem',
        '180': '45rem',
        '192': '48rem',
        '200': '50rem',
        '240': '60rem',
        '256': '64rem',
        '280': '70rem',
        '320': '80rem',
        '360': '90rem',
        '400': '100rem',
        '480': '120rem',

        // '128': '32rem',
        // '200': '50rem',
      },
      gridAutoRows: {
        '35': '8.75rem',
      },
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        mw: '#ff640c',
        default: '#212529',
        orange: {
          50: '#ffede2',
          100: '#ffd1b6',
          200: '#ffb286',
          300: '#ff9355',
          400: '#ff7c30',
          500: '#ff650c',
          600: '#ff5d0a',
          700: '#ff5308',
          800: '#ff4906',
          900: '#ff3703',
          A100: '#ffffff',
          A200: '#fff4f2',
          A400: '#ffc9bf',
          A700: '#ffb4a6',
          light: '#ff9744',
          dark: '#c43200',
        },
        blue: {
          50: '#e5f1ff',
          100: '#beddff',
          200: '#93c7ff',
          300: '#67b0ff',
          400: '#479fff',
          500: '#268eff',
          600: '#2286ff',
          700: '#1c7bff',
          800: '#1771ff',
          900: '#0d5fff',
          A100: '#ffffff',
          A200: '#f6f9ff',
          A400: '#c3d5ff',
          A700: '#aac3ff',
          light: '#73beff',
          dark: '#0062cb',
        },
        red: {
          50: '#ffe3e0',
          100: '#ffb8b3',
          200: '#ff8980',
          300: '#ff5a4d',
          400: '#ff3626',
          500: '#ff1300',
          600: '#ff1100',
          700: '#ff0e00',
          800: '#ff0b00',
          900: '#ff0600',
          A100: '#ffffff',
          A200: '#fff2f2',
          A400: '#ffc0bf',
          A700: '#ffa6a6',
          light: '#ff5e36',
          dark: '#c20000',
        },
        yellow: {
          50: '#fffce3',
          100: '#fef7ba',
          200: '#fef28c',
          300: '#fded5e',
          400: '#fce93c',
          500: '#fce519',
          600: '#fce216',
          700: '#fbde12',
          800: '#fbda0e',
          900: '#fad308',
          A100: '#ffffff',
          A200: '#fffcef',
          A400: '#fff3bc',
          A700: '#ffeea2',
          light: '#ffff5c',
          dark: '#c5b300',
        },
        lime: {
          50: '#e9fdec',
          100: '#c8f9cf',
          200: '#a3f5af',
          300: '#7ef18e',
          400: '#62ee76',
          500: '#46eb5e',
          600: '#3fe956',
          700: '#37e54c',
          800: '#2fe242',
          900: '#20dd31',
          A100: '#ffffff',
          A200: '#e1ffe3',
          A400: '#aeffb4',
          A700: '#95ff9d',
          light: '#84ff8f',
          dark: '#00b72c',
        },
        green: {
          50: '#e4f9ed',
          100: '#bcf0d2',
          200: '#8fe6b4',
          300: '#62db96',
          400: '#41d480',
          500: '#1fcc69',
          600: '#1bc761',
          700: '#17c056',
          800: '#12b94c',
          900: '#0aad3b',
          A100: '#daffe3',
          A200: '#a7ffbd',
          A400: '#74ff96',
          A700: '#5aff83',
          light: '#67ff98',
          dark: '#009a3c',
        },
        purple: {
          50: '#e6e7ea',
          100: '#c1c3c9',
          200: '#989ca6',
          300: '#6e7482',
          400: '#4f5667',
          500: '#30384c',
          600: '#2b3245',
          700: '#242b3c',
          800: '#1e2433',
          900: '#131724',
          A100: '#678aff',
          A200: '#3463ff',
          A400: '#013bff',
          A700: '#0035e6',
          light: '#5a6278',
          dark: '#091224',
        },
        amber: {
          50: '#fff5e4',
          100: '#ffe6ba',
          200: '#ffd58d',
          300: '#ffc45f',
          400: '#ffb83c',
          500: '#ffab1a',
          600: '#ffa417',
          700: '#ff9a13',
          800: '#ff910f',
          900: '#ff8008',
          A100: '#ffffff',
          A200: '#fff9f4',
          A400: '#ffdcc1',
          A700: '#ffcea7',
          light: '#ffdd54',
          dark: '#c67c00',
        },
        gray: {
          50: '#ecedf1',
          100: '#cfd3dc',
          200: '#afb6c4',
          300: '#8e99ac',
          400: '#76839b',
          500: '#5e6d89',
          600: '#566581',
          700: '#4c5a76',
          800: '#42506c',
          900: '#313e59',
          A100: '#a7c2ff',
          A200: '#749fff',
          A400: '#417cff',
          A700: '#286aff',
          light: '#8c9bb9',
          dark: '#33425c',
        },
        zinc: {
          50: '#e4e4e4',
          100: '#bdbdbd',
          200: '#919191',
          300: '#646464',
          400: '#434343',
          500: '#222222',
          600: '#1e1e1e',
          700: '#191919',
          800: '#141414',
          900: '#0c0c0c',
          A100: '#e76c6c',
          A200: '#e04040',
          A400: '#ec0000',
          A700: '#d30000',
          light: '#494949',
          dark: '#000000',
        },
      },
      width: {
        '1/7': '14.285714%',
        '1/8': '12.5%',
      },
      minWidth: {
        '1/2': '50%',
      },
      minHeight: {
        '5': '1.25rem',
      },
    },
  },
  plugins: [
    plugin(({addVariant, theme, e}) => {
      addVariant('invalid', ({modifySelectors, separator}) => {
        modifySelectors(({className}) =>
          `.${e(`invalid${separator}${className}`)}:invalid`,
        );
      });
    }),
    // require('@tailwindcss/forms'),
  ],
};
