import {useContext} from 'react';
import {DialogContext} from '@core/providers';
import {DialogsType} from '@core/types';

export const useDialogs = (): DialogsType => {
  const result = useContext(DialogContext);

  if (!result) {
    throw new Error('Dialog context is only available inside its provider');
  }

  return result;
};
