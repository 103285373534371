import {useDocumentOnce} from 'react-firebase-hooks/firestore';
import {doc} from 'firebase/firestore';
import {firebaseFirestore} from '@core/firebase';
import {useEffect, useState} from 'react';
import {Equipment, EquipmentsItem} from '@modules/equipments/types';
import {equipmentsConverterFirestore} from '@modules/equipments/firestore';

export const useEquipmentsItem = (id: string): EquipmentsItem => {
  const [item, setItem] = useState<Equipment>();
  const [exists, setExists] = useState<boolean>(true);
  const [value, loading, error] = useDocumentOnce(
    doc(firebaseFirestore, 'equipments', id).withConverter(equipmentsConverterFirestore),
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    const data = value.data();

    setExists(value.exists());
    setItem(data);
  }, [value]);

  return [item, loading, error, exists];
};
