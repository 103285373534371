import React, {memo, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Entity} from '@modules/entities/types';
import {useEntitiesItem} from '@modules/entities/hooks';
import {useAuth} from '@core/hooks';
import {Button, Tooltip} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {get, PropertyPath} from 'lodash';
import {getMarkdownText} from '@core/utils';

export const EntitiesDetailPage: React.FC = memo(() => {
  const {id} = useParams<{ id: string }>();
  const [entity, loading, , exists] = useEntitiesItem(String(id));
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (id && !loading && !exists) {
      navigate('/404');
    }
  }, [loading, exists, id, navigate]);

  const handleEditItem = (): void => {
    if (!entity?.id) {
      return;
    }

    navigate(`/entities/${entity.id}/edit`);
  };

  const handleReturn = (): void => {
    navigate('/entities');
  };

  const list: { name: string; value: (keyof Entity | PropertyPath | ((row: Entity) => string | JSX.Element)) }[] = [
    {name: 'Пользователь', value: 'data.owner'},
    {name: 'CPU', value: 'refs.cpu.data.name'},
    {name: 'MB', value: 'refs.mb.data.name'},
    {name: 'SSD', value: 'refs.ssd.data.name'},
    {name: 'HDD', value: 'refs.hdd.data.name'},
    {name: 'RAM', value: (row) => row.data.ram?.join('/') ?? ''},
    {name: 'GPU', value: 'refs.gpu.data.name'},
    {name: 'PSU', value: 'refs.psu.data.name'},
    {name: 'Монитор 1', value: 'refs.monitor1.data.name'},
    {name: 'Монитор 2', value: 'refs.monitor2.data.name'},
    {name: 'Хост', value: 'data.host'},
    {
      name: 'Комментарий', value: (row) =>
        <div dangerouslySetInnerHTML={getMarkdownText(row.data.comment ?? '')}/>,
    },
  ];

  if (loading) {
    return <div className="w-full text-center py-6 text-gray-500">Загрузка</div>;
  }

  return entity?.id ? (
    <div className="min-w-1/2 mx-auto sm:py-12 sm:px-6 lg:px-8 self-center w-full overflow-auto">
      <div className="max-w-4xl mx-auto">

        <div className="bg-white shadow overflow-auto sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex flex-row">
            {auth.user &&
              <div className="mr-4 self-center">
                <Tooltip title="Вернуться к списку">
                  <button
                    className="flex items-center w-12 h-12 rounded-full justify-center hover:bg-gray-100"
                    onClick={handleReturn}
                  >
                    <ArrowBackIcon/>
                  </button>
                </Tooltip>
              </div>
            }
            <div className="flex-1">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Информация о технике &quot;{entity.id}&quot;</h3>
              <p className="mt-1 max-w-2xl text-gray-500">Технические характеристики</p>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {list.map((item, index) => (
                <div
                  key={item.name}
                  className={`${index % 2 ? 'bg-white-50' : 'bg-gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                >
                  <dt className="font-medium text-gray-500">{item.name}</dt>
                  <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                    {(typeof item.value === 'function' ? item.value(entity) : get(entity, item.value)) || '-'}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          {auth.user &&
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Button
                variant="contained"
                disableElevation
                onClick={handleEditItem}
              >
                Редактировать
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full text-center py-6 text-gray-500">Нет данных</div>
  );
});
