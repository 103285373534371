import tailwindConfig from './tailwind.app';
import resolveConfig from 'tailwindcss/resolveConfig';
import {createTheme} from '@mui/material';

const htmlFontSize = 16;
const fullConfig = resolveConfig(tailwindConfig);
// console.log(fullConfig.theme);

// const pxToRem = (value: number): number => {
//   return value / htmlFontSize;
// };

const remToPx = (value: number): number => {
  return value * htmlFontSize;
};

export const appMuiTheme = createTheme({
  palette: {
    // mode: 'dark',
    common: {
      // @ts-ignore
      white: fullConfig.theme.colors.black,
      // @ts-ignore
      black: fullConfig.theme.colors.white,
    },
    primary: {
      // @ts-ignore
      light: fullConfig.theme.colors.orange.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.orange.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.orange['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    secondary: {
      // @ts-ignore
      light: fullConfig.theme.colors.purple.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.purple.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.purple['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    error: {
      // @ts-ignore
      light: fullConfig.theme.colors.red.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.red.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.red['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    warning: {
      // @ts-ignore
      light: fullConfig.theme.colors.amber.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.amber.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.amber['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    info: {
      // @ts-ignore
      light: fullConfig.theme.colors.blue.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.blue.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.blue['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    success: {
      // @ts-ignore
      light: fullConfig.theme.colors.green.light,
      // @ts-ignore
      dark: fullConfig.theme.colors.green.dark,
      // @ts-ignore
      main: fullConfig.theme.colors.green['500'],
      // @ts-ignore
      contrastText: fullConfig.theme.colors.white,
    },
    // @ts-ignore
    grey: fullConfig.theme.colors.gray,

    text: {
      // @ts-ignore
      primary: fullConfig.theme.colors.zinc['500'],
      // @ts-ignore
      secondary: fullConfig.theme.colors.zinc['400'],
      // @ts-ignore
      disabled: fullConfig.theme.colors.zinc['300'],
    },

    background: {
      // @ts-ignore
      default: fullConfig.theme.colors.white,
      // @ts-ignore
      paper: fullConfig.theme.colors.white,
    },
  },
  typography: {
    htmlFontSize: htmlFontSize,
    // fontFamily: '',
    // @ts-ignore
    fontSize: remToPx(parseFloat(fullConfig.theme.fontSize.base)),
    // @ts-ignore
    fontWeightLight: parseInt(fullConfig.theme.fontWeight.light),
    // @ts-ignore
    fontWeightRegular: parseInt(fullConfig.theme.fontWeight.normal),
    // @ts-ignore
    fontWeightMedium: parseInt(fullConfig.theme.fontWeight.medium),
    // @ts-ignore
    fontWeightBold: parseInt(fullConfig.theme.fontWeight.bold),
  },
  shape: {
    // @ts-ignore
    borderRadius: remToPx(parseFloat(fullConfig.theme.borderRadius.md)),
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          // @ts-ignore
          backgroundColor: fullConfig.theme.colors.gray['50'],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          // @ts-ignore
          fontWeight: fullConfig.theme.fontWeight.bold,
          // @ts-ignore
          color: fullConfig.theme.colors.gray['500'],
          // @ts-ignore
          textTransform: 'uppercase',
          // @ts-ignore
          letterSpacing: fullConfig.theme.letterSpacing.wider,
        },
        root: {},
      },
    },
    // MuiAppBar: {
    //   styleOverrides: {
    //     colorPrimary: {
    //       // @ts-ignore
    //       backgroundColor: fullConfig.theme.colors.zinc['500'],
    //     },
    //   },
    // },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // @ts-ignore
          color: fullConfig.theme.colors.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          alignItems: 'baseline',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          // @ts-ignore
          color: fullConfig.theme.colors.gray['200'],
          // @ts-ignore
          // fontSize: fullConfig.theme.fontSize['2xl'],
        },
      },
    },
  },
});

// console.log(appMuiTheme);

export const snackbarClasses = {
  variantSuccess: 'bg-green-500',
  variantError: 'bg-red-500',
  variantInfo: 'bg-blue-500',
  variantWarning: 'bg-amber-500',
};
