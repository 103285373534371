import {useCallback} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {firebaseAuth} from '@core/firebase';
import {AuthHook} from '@core/types';
import {signInWithEmailAndPassword, signOut} from 'firebase/auth';

export const useAuth = (): AuthHook => {
  const [user, loading, error] = useAuthState(firebaseAuth);

  const signin = useCallback(
    (email: string, password: string) => signInWithEmailAndPassword(firebaseAuth, email, password),
    [],
  );

  const signout = useCallback(
    () => signOut(firebaseAuth),
    [],
  );

  return {user, signout, signin, loading, error};
};
