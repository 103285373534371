import {ComposeProps} from '@core/types';
import React, {ReactElement, ReactNode} from 'react';

export default function ComposeProvider(props: ComposeProps): ReactElement {
  const {items = [], children} = props;

  return (
    <>
      {items.reduceRight((acc: ReactNode, [Comp, props]) => <Comp {...props}>{acc}</Comp>, children)}
    </>
  );
}
