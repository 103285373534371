import React, {memo, useEffect} from 'react';
import {Path, useForm} from 'react-hook-form';
import {forIn, get, map, orderBy} from 'lodash';
import {Button, InputAdornment} from '@mui/material';
import {EntitiesFormComponentProps, EntityForm, EntityStatuses} from '@modules/entities/types';
import {useEquipmentsCreate, useEquipmentsList} from '@modules/equipments/hooks';
import {EquipmentTypes} from '@modules/equipments/types';
import {EntitiesRamControlComponent} from '@modules/entities/components';
import {useDialogs} from '@core/hooks';
import {AutocompleteControlOption, DialogActionRole, DialogType} from '@core/types';
import {AutocompleteControlComponent, SelectControlComponent, TextFieldControlComponent} from '@core/components';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NotesIcon from '@mui/icons-material/Notes';
import DnsIcon from '@mui/icons-material/Dns';
import MonitorIcon from '@mui/icons-material/Monitor';
import DevicesIcon from '@mui/icons-material/Devices';
import StorageIcon from '@mui/icons-material/Storage';
import BoltIcon from '@mui/icons-material/Bolt';
import TerrainIcon from '@mui/icons-material/Terrain';
import MemoryIcon from '@mui/icons-material/Memory';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

export const EntitiesFormComponent = memo((props: EntitiesFormComponentProps) => {
  const {entity, onSubmit, saveButtonText, onCancel} = props;
  const {control, handleSubmit, setValue, getFieldState, reset, resetField} = useForm<EntityForm>({mode: 'all'});
  const [equipments] = useEquipmentsList();
  const [createEquipment] = useEquipmentsCreate();
  const dialogs = useDialogs();
  const statuses = map([
    {name: 'Все нормально', value: EntityStatuses.success, color: 'green'},
    {name: 'Есть замечания', value: EntityStatuses.warning, color: 'amber'},
    {name: 'Проблема', value: EntityStatuses.danger, color: 'red'},
  ], (item) => ({
    ...item,
    name: (<div className="flex items-center"><span className={`inline-block h-2 w-2 ml-2 mr-3 rounded-full bg-${item.color}-500`}/><span>{item.name}</span></div>),
  }));

  useEffect(() => {
    if (!entity?.data || !equipments) {
      return;
    }

    // - data -
    forIn(entity.data, (value: string | number[] | undefined, key: string) => {
      if (getFieldState(key as keyof EntityForm).isDirty) {
        return;
      }

      resetField(key as keyof EntityForm, {defaultValue: value});
    });

    // - refs -
    forIn(entity.refs, (value: unknown, key: string) => {
      if (getFieldState(key as keyof EntityForm).isDirty) {
        return;
      }

      resetField(key as keyof EntityForm, {defaultValue: get(value, 'snapshot.ref.path')});
    });
  }, [entity, equipments, resetField, getFieldState]);

  const mapListSelect = (label: EquipmentTypes): AutocompleteControlOption[] =>
    orderBy(
      (equipments ?? [])
        .filter((e) => e.data.type === label)
        .map(({data, id, snapshot}) => ({
          name: data?.name ?? '',
          label: data?.name ?? '',
          id: id,
          path: snapshot.ref.path,
        })),
      'name',
    );

  const addEquipment = (data: AutocompleteControlOption, name: Path<EntityForm>, type: EquipmentTypes): void => {
    createEquipment({
      name: data.name,
      type: type,
    }).then((data) => {
      setValue(name, data.ref.path);
    });
  };

  const handleReset = (): void => {
    dialogs.show({
      title: 'Сбросить',
      message: 'Вы действительно хотите сбросить все изменения?',
      type: DialogType.warning,
      actions: [
        {
          text: 'Сбросить',
          role: DialogActionRole.yes,
          handler: (): void => {
            reset({}, {keepDefaultValues: true});
          },
        },
        {text: 'Отмена'},
      ],
    });
  };

  return (
    <>
      {/* form*/}
      <form
        // onSubmit={handleSubmit((data) => console.log(data))}
        onSubmit={handleSubmit((data) => onSubmit && onSubmit(data))}
        className="flex flex-col h-full"
      >
        <div className="px-6 py-10 bg-white space-y-10 sm:p-10 flex-1">

          {/* name*/}
          <div>
            <TextFieldControlComponent<EntityForm>
              control={control}
              name="owner"
              label="Пользователь"
              textFieldProps={{
                autoFocus: true,
                InputProps: {
                  placeholder: 'Имя пользователя',
                  startAdornment: (<InputAdornment position="start"><AssignmentIndIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* status*/}
          <div>
            <SelectControlComponent<EntityForm>
              control={control}
              name="status"
              // controllerProps={{
              //   rules: {required: 'Необходимо указать тип'},
              // }}
              controllerProps={{
                defaultValue: EntityStatuses.success,
              }}
              label="Статус"
              list={statuses}
              // textFieldProps={{
              //   // className: 'flex ',
              //   InputProps: {
              //     // placeholder: 'Тип записи',
              //     startAdornment: (<InputAdornment position="start"><ListIcon fontSize="small"/></InputAdornment>),
              //   },
              // }}
            />
          </div>

          {/* device*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="Тип"
              options={mapListSelect(EquipmentTypes.device)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.device)}
              name="device"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Тип устройства',
                  startAdornment: (<InputAdornment position="start"><DevicesIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* cpu*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="CPU"
              options={mapListSelect(EquipmentTypes.cpu)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.cpu)}
              name="cpu"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Процессор',
                  startAdornment: (<InputAdornment position="start"><MemoryIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* mb*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="MB"
              options={mapListSelect(EquipmentTypes.mb)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.mb)}
              name="mb"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Материнская плата',
                  startAdornment: (<InputAdornment position="start"><GroupWorkIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* ram*/}
          <div>
            <EntitiesRamControlComponent
              control={control}
              name="ram"
              label="RAM"
            />
          </div>

          {/* ssd*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="SSD"
              options={mapListSelect(EquipmentTypes.ssd)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.ssd)}
              name="ssd"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Твердотельный накопитель',
                  startAdornment: (<InputAdornment position="start"><StorageIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* hdd*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="HDD"
              options={mapListSelect(EquipmentTypes.hdd)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.hdd)}
              name="hdd"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Жесткий диск',
                  startAdornment: (<InputAdornment position="start"><StorageIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* gpu*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="GPU"
              options={mapListSelect(EquipmentTypes.gpu)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.gpu)}
              name="gpu"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Видео-карта',
                  startAdornment: (<InputAdornment position="start"><TerrainIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* psu*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="PSU"
              options={mapListSelect(EquipmentTypes.psu)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.psu)}
              name="psu"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Блок питания',
                  startAdornment: (<InputAdornment position="start"><BoltIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* monitor1*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="Монитор 1"
              options={mapListSelect(EquipmentTypes.monitor)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.monitor)}
              name="monitor1"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Первый монитор',
                  startAdornment: (<InputAdornment position="start"><MonitorIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* monitor2*/}
          <div>
            <AutocompleteControlComponent<EntityForm>
              control={control}
              disabled={!equipments?.length}
              label="Монитор 2"
              options={mapListSelect(EquipmentTypes.monitor)}
              onAdd={(...args): void => addEquipment(...args, EquipmentTypes.monitor)}
              name="monitor2"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Второй монитор',
                  startAdornment: (<InputAdornment position="start"><MonitorIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* host*/}
          <div>
            <TextFieldControlComponent<EntityForm>
              control={control}
              name="host"
              label="Хост"
              textFieldProps={{
                InputProps: {
                  placeholder: 'Сетевое имя',
                  startAdornment: (<InputAdornment position="start"><DnsIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

          {/* comment*/}
          <div>
            <TextFieldControlComponent<EntityForm>
              control={control}
              name="comment"
              label="Комментарий"
              textFieldProps={{
                multiline: true,
                rows: 10,
                InputProps: {
                  placeholder: 'Комментарии и заметки',
                  startAdornment: (<InputAdornment position="start"><NotesIcon fontSize="small"/></InputAdornment>),
                },
              }}
            />
          </div>

        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 grid grid-cols-1 md:grid-cols-4 gap-1">
          <Button
            type="button"
            variant="contained"
            disableElevation
            color="secondary"
            className="w-full md:w-auto order-3 md:order-1"
            onClick={handleReset}
          >
            Сбросить
          </Button>

          <Button
            type="reset"
            variant="contained"
            disableElevation
            color="secondary"
            className="w-full md:w-auto order-2 md:order-2"
            onClick={(): void => onCancel && onCancel()}
          >
            Отмена
          </Button>

          <div className="md:hidden h-0 my-2 border-b w-full order-1 md:order-3"/>

          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="w-full md:w-auto md:col-span-2 order-0 md:order-3"
          >
            {saveButtonText ?? 'Сохранить'}
          </Button>
        </div>
      </form>
      {/* end form*/}
    </>
  );
});
