import {useNavigate} from 'react-router-dom';
import {EquipmentsFormComponent} from '@modules/equipments/components';
import {useEquipmentsCreate} from '@modules/equipments/hooks';
import React, {memo} from 'react';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useSnackbar} from 'notistack';
import {EquipmentForm} from '@modules/equipments/types';

export const EquipmentsCreatePage = memo(() => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [create] = useEquipmentsCreate();

  const navigateToList = (): void => {
    navigate('/equipments');
  };

  const handleSubmit = (data: EquipmentForm): void => {
    create(data).then(({id}) => {
      enqueueSnackbar(
        `Запись "${id}" успешно создана`,
        {variant: 'success'},
      );
      // navigate('/equipments');
      navigateToList();
    });
  };

  const handleChange = (data: EquipmentForm): void => {
    // e.preventDefault();
    // onSubmit();
    // console.log('change', data);
  };

  return (
    <>
      <div className="bg-gray-50 p-6 sm:py-6 sm:px-10">
        <div className="flex">
          <Typography variant="h5" className="text-gray-900 flex-1 truncate">Новый</Typography>
          <Tooltip title="Закрыть">
            <IconButton onClick={navigateToList}>
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Создание новой записи</p>
      </div>

      <div className="relative flex-1">
        <EquipmentsFormComponent
          saveButtonText="Создать"
          onSubmit={handleSubmit}
          onChange={handleChange}
          onCancel={navigateToList}
        />
      </div>
    </>
  );
});
