import {useCollection} from 'react-firebase-hooks/firestore';
import {collection, FirestoreDataConverter, orderBy, query} from 'firebase/firestore';
import {firebaseFirestore} from '@core/firebase';
import {useEffect, useState} from 'react';
import {Equipment, EquipmentsList} from '@modules/equipments/types';
import {equipmentsConverterFirestore} from '@modules/equipments/firestore';

export const useEquipmentsList = (): EquipmentsList => {
  const [list, setList] = useState<Equipment[]>();
  const [value, loading, error] = useCollection(
    query(
      collection(firebaseFirestore, 'equipments').withConverter(equipmentsConverterFirestore as FirestoreDataConverter<Equipment>),
      orderBy('createAt', 'desc'),
    ),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    setList(value.docs.map((doc) => doc.data()));
  }, [value]);

  return [list, loading, error];
};
